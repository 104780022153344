import { combineReducers } from '@reduxjs/toolkit';
import { BusinessReducer } from './main/slice';
import { AdvertisingReducer } from './advertising/slice';
import { ClientsCorrespondenceReducer } from './clientsCorrespondence/slice';
import { ContactsReducer } from './contacts/slice';
import { ControlPanelReducer } from './controlPanel/slice';
import { CorrespondenceMGReducer } from './correspondenceMG/slice';
import { DocumentFlowReducer } from './documentFlow/slice';
import { GoodsReducer } from './goods/slice';
import { ModulesReducer } from './modules/slice';
import { MyBalanceReducer } from './myBalance/slice';
import { OrdersReducer } from './orders/slice';
import { PaymentManagementReducer } from './paymentManagement/slice';
import { ProductManagementReducer } from './productManagement/slice';
import { ReviewsReducer } from './reviews/slice';
import { SettingsReducer } from './settings/slice';
import { CRMReducer } from './crm/slice';
import { DealsReducer } from './deals/slice';
import { integrationsReducer } from './integrations/slice';
import { StaffReducer } from './staff/slice';

const businessAppReducer = combineReducers({
	main: BusinessReducer,
	advertising: AdvertisingReducer,
	clientsCorrespondence: ClientsCorrespondenceReducer,
	contacts: ContactsReducer,
	controlPanel: ControlPanelReducer,
	correspondenceMG: CorrespondenceMGReducer,
	documentFlow: DocumentFlowReducer,
	goods: GoodsReducer,
	modules: ModulesReducer,
	myBalance: MyBalanceReducer,
	orders: OrdersReducer,
	paymentManagement: PaymentManagementReducer,
	productManagement: ProductManagementReducer,
	reviews: ReviewsReducer,
	settings: SettingsReducer,
	crm: CRMReducer,
	deals: DealsReducer,
	integrations: integrationsReducer,
	staff: StaffReducer,
});

export const businessRootReducer = (state, action) => {
	if (action.type === 'business/setResetBusinessState') {
		return businessAppReducer(undefined, action); // Скидаємо стан
	}

	return businessAppReducer(state, action); // Відновлюєм стан
};
