export const AllCRMSelector = (state) => state.business.main.activeBusiness?.crms;

export const activeCRMStatusesSelector = (state) => state.business.crm.CRMStatuses;

export const ordersSelector = (state) => state.business.crm.orders;

export const activeOrderSelector = (state) => state.business.crm.activeOrder;

export const moreOnlineResponsiblePersonsSelector = (state) =>
	state.business.crm.moreOnlineResponsiblePersons;

export const aboutLeadOnlineDynamicOwnFieldsSelector = (state) =>
	state.business.crm.aboutLeadOnlineDynamicOwnFields;

export const activeTasksStatusesSelector = (state) => state.business.crm.tasksStatuses;

export const tasksSelector = (state) => state.business.crm.tasks;

export const taskTemplatesSelector = (state) => state.business.crm.taskTemplates;

export const preloadGoogleFilesSelector = (state) => state.business.crm.preloadGoogleFiles;

export const archivedTasksSelector = (state) => state.business.crm.archivedTasks;

export const clientOptionsSelector = (state) => state.business.crm.clientOptions;

export const inputValueSelector = (state) => state.business.crm.inputValue;

export const prefixUrlSelector = (state) => state.business.crm.prefixUrl;

export const tasksChecklistSelector = (state) => state.business.crm.tasksChecklist;
