import { createSlice } from '@reduxjs/toolkit';
import {
	handleActiveBusiness,
	handleActiveUser,
	handleExchangeRate,
	
} from './helpers';

const BusinessSlice = createSlice({
	name: 'business',
	initialState: {
		activeBusiness: null,
		activeUser: null,
		exchangeRate: null,
	},
	reducers: {
		setActiveBusiness: handleActiveBusiness,
		setActiveUser: handleActiveUser,
		setExchangeRate: handleExchangeRate,
		setResetBusinessState: () => {},
	},
	extraReducers: () => {},
});

export const BusinessReducer = BusinessSlice.reducer;
export const { setActiveBusiness, setActiveUser, setExchangeRate, setResetBusinessState } =
	BusinessSlice.actions;
