import { createSlice } from '@reduxjs/toolkit';
import {
	handleCRMStatuses,
	handleOrders,
	handleActiveOrder,
	handleMoreOnlineResponsiblePersons,
	handleAboutLeadOnlineDynamicOwnFields,
	handleTasksStatuses,
	handleTasks,
	handleTaskTemplates,
	handlePreloadGoogleFiles,
	handleArchivedTasks,
	handleTaskHistory,
	handleTasksChecklist,
} from './helpers';

const CRMSlice = createSlice({
	name: 'business/crm',
	initialState: {
		clientOptions: [],
		inputValue: '',
		phoneValue: '',
		nameValue: '',
		prefixUrl: '/assets/img/avatar.png',
		CRMStatuses: [],
		taskHistory: [],
		orders: null,
		activeOrder: null,
		moreOnlineResponsiblePersons: null,
		aboutLeadOnlineDynamicOwnFields: null,
		tasksStatuses: [],
		tasks: null,
		taskTemplates: null,
		preloadGoogleFiles: null,
		archivedTasks: [],
		tasksChecklist: null,
	},
	reducers: {
		addTaskHistory: (state, action) => {
			state.taskHistory.push(action.payload);
		},
		setClientOptions: (state, action) => {
			state.clientOptions = action.payload;
		},
		setInputValue: (state, action) => {
			state.inputValue = action.payload;
		},
		setPrefixUrl: (state, action) => {
			state.prefixUrl = action.payload;
		},
		setPhoneValue: (state, action) => {
			state.phoneValue = action.payload;
		},
		setNameValue: (state, action) => {
			state.nameValue = action.payload;
		},
		setCRMStatuses: handleCRMStatuses,
		setOrders: handleOrders,
		setActiveOrder: handleActiveOrder,
		setMoreOnlineResponsiblePersons: handleMoreOnlineResponsiblePersons,
		setAboutLeadOnlineDynamicOwnFields: handleAboutLeadOnlineDynamicOwnFields,
		setTasksStatuses: handleTasksStatuses,
		setTasks: handleTasks,
		setTaskTemplates: handleTaskTemplates,
		setPreloadGoogleFiles: handlePreloadGoogleFiles,
		setArchivedTasks: handleArchivedTasks,
		setTaskHistory: handleTaskHistory,
		setTasksChecklist: handleTasksChecklist,
	},
	extraReducers: () => {},
});

export const CRMReducer = CRMSlice.reducer;

export const {
	addTaskHistory,
	setCRMStatuses,
	setOrders,
	setClientOptions,
	setInputValue,
	setPrefixUrl,
	setNameValue,
	setPhoneValue,
	setActiveOrder,
	setMoreOnlineResponsiblePersons,
	setAboutLeadOnlineDynamicOwnFields,
	setTasksStatuses,
	setTasks,
	setTaskTemplates,
	setPreloadGoogleFiles,
	setArchivedTasks,
	setTaskHistory,
	setTasksChecklist,
} = CRMSlice.actions;
